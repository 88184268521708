import React, { useEffect } from 'react';
import { Close, Footer, FooterItem, Header, Modal } from '@zendeskgarden/react-modals';
import { ModalBodyStyling, ModalLoading } from '../components/Editor';
import { STATUS_IS_ERROR, STATUS_IS_PENDING } from '../constants';
import { Dots } from '@zendeskgarden/react-loaders';
import { Button } from '@zendeskgarden/react-buttons';
import useDeleteDesign from '../hooks/useDeleteDesign';

const DeleteModal = ({modalVisible, setModalVisible, emailName, id}) => {
  const [deleteDesign, status] = useDeleteDesign(id);

  useEffect(() => {
    if (status === STATUS_IS_ERROR) {
      setModalVisible(false);
    }
  }, [status, setModalVisible]);

  if (!modalVisible) {
    return '';
  }

  return (
    <Modal onClose={() => setModalVisible(false)}>
      <Header>Delete theme "{emailName}"?</Header>
      <ModalBodyStyling>
        {status === STATUS_IS_PENDING && (
          <ModalLoading>
            <Dots size={32}/>
          </ModalLoading>
        )}
        Are you sure you want to delete the theme "{emailName}"?
      </ModalBodyStyling>
      <Footer>
        <FooterItem>
          <Button onClick={() => setModalVisible(false)} isBasic>
            Cancel
          </Button>
        </FooterItem>
        <FooterItem>
          <Button isPrimary isDanger onClick={deleteDesign}>
            Delete
          </Button>
        </FooterItem>
      </Footer>
      <Close aria-label="Close modal"/>
    </Modal>
  );
};

export default DeleteModal;
