import React, { createContext, useState } from 'react';
import { UserPageBox, UserPageContainer, UserPageError, UserPageUser } from '../components/User';
import { GOOGLE_CLIENT_ID, STATUS_IS_PENDING, USER_HOSTED_DOMAIN } from '../constants';
import GoogleLogin, { useGoogleLogin, useGoogleLogout } from 'react-google-login';
import { PALETTE } from '@zendeskgarden/react-theming';
import { Avatar } from '@zendeskgarden/react-avatars';
import { MD, XL } from '@zendeskgarden/react-typography';
import { Alert } from '@zendeskgarden/react-notifications';
import { Dots } from '@zendeskgarden/react-loaders';
import { Dropdown, Item, Menu, Trigger } from '@zendeskgarden/react-dropdowns';
import useUser from '../hooks/useUser';

export const UserContext = createContext({});

export const UserLogout = ({userData}) => {
  const {logout} = useUser();
  const {signOut} = useGoogleLogout({clientId: GOOGLE_CLIENT_ID, onLogoutSuccess: logout});

  return (
    <Dropdown onSelect={() => signOut()}>
      <Trigger>
        <Avatar backgroundColor={PALETTE.grey[600]} style={{cursor: 'pointer'}}>
          <img alt={userData.name} src={userData.imageUrl}/>
        </Avatar>
      </Trigger>
      <Menu>
        <Item value="logout">Log Out</Item>
      </Menu>
    </Dropdown>
  );
};

const User = ({children}) => {
  const {loaded} = useGoogleLogin({clientId: GOOGLE_CLIENT_ID});
  const [userData, setUserData] = useState(null);
  const [loginError, setLoginError] = useState(null);
  const {login, status, getCache} = useUser();
  const [cachedUsers, cachedUserId] = getCache();

  const handleLoginSuccess = async (response) => {
    const profile = response.getBasicProfile();
    setLoginError(null);

    const profileData = {
      id: profile.getId(),
      email: profile.getEmail(),
      familyName: profile.getFamilyName(),
      givenName: profile.getGivenName(),
      name: profile.getName(),
      imageUrl: profile.getImageUrl(),
      hostedDomain: response.getHostedDomain()
    };

    if (!(cachedUsers && cachedUserId && cachedUserId === profileData.id)) {
      await login(profileData);
    }

    setUserData(profileData);
  };

  const handleLoginError = (error) => {
    console.log('Login error', error);
    setLoginError(error.details || error.error);
  };

  if (!loaded || status === STATUS_IS_PENDING) {
    return (
      <UserPageContainer>
        <Dots size={32}/>
      </UserPageContainer>
    );
  }

  if (!loginError && userData && userData.hostedDomain === USER_HOSTED_DOMAIN) {
    return (
      <UserContext.Provider value={userData}>
        {children}
      </UserContext.Provider>
    );
  }

  return (
    <UserPageContainer>
      <UserPageBox>
        {userData && (
          <UserPageUser>
            <Avatar backgroundColor={PALETTE.grey[600]} size="large">
              <img alt={userData.name} src={userData.imageUrl}/>
            </Avatar>
            <XL>{userData.name}</XL>
            <MD style={{color: PALETTE.grey[600]}}>{userData.email}</MD>
          </UserPageUser>
        )}

        {(userData || loginError) && (
          <UserPageError>
            <Alert type="error">
              {loginError ? loginError : 'Access is denied'}
            </Alert>
          </UserPageError>
        )}
        <GoogleLogin
          clientId={GOOGLE_CLIENT_ID}
          onSuccess={handleLoginSuccess}
          onFailure={handleLoginError}
          cookiePolicy={'single_host_origin'}
          isSignedIn={true}
        />
      </UserPageBox>
    </UserPageContainer>
  );
};

export default User;
