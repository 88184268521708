import React, { useCallback, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import EmailEditor from 'react-email-editor';
import { EditorContainer } from '../components/Editor';
import { Body, Content, Main } from '@zendeskgarden/react-chrome';
import { Button } from '@zendeskgarden/react-buttons';
import { Liquid } from 'liquidjs';
import {
  EditorHeaderLink,
  EditorTab,
  EditorTabList,
  EditorTabPanel,
  EditorTabs,
  HeaderCol,
  HeaderItemStyled,
  HeaderRow
} from '../components/EditorHeader';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { vs2015 } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import jsonFormat from 'json-format';
import { ReactComponent as ChevronLeftIcon } from '@zendeskgarden/svg-icons/src/16/chevron-left-fill.svg';
import { ReactComponent as ChevronDownIcon } from '@zendeskgarden/svg-icons/src/16/chevron-down-stroke.svg';
import editorOptions from '../editor/options';
import previewTemplates from '../editor/previewTemplates';
import blankDesign from '../editor/designs/1-blank.json';
import { UserLogout } from './User';
import { Dropdown, Item, Menu, Separator, Trigger } from '@zendeskgarden/react-dropdowns';
import { STATUS_IS_PENDING } from '../constants';
import { Dots } from '@zendeskgarden/react-loaders';
import { LG } from '@zendeskgarden/react-typography';
import CopyButton from '../components/CopyButton';
import SaveUsModal from './SaveUsModal';
import useCurrentDesign from '../hooks/useCurrentDesign';
import useSaveDesign from '../hooks/useSaveDesign';
import { Input } from '@zendeskgarden/react-forms';
import DeleteModal from './DeleteModal';
import SettingsModal from './SettingsModal';

const Editor = ({userData}) => {
  const {id} = useParams();
  const emailEditorRef = useRef(null);
  const [selectedTab, setSelectedTab] = useState('tab-editor');
  const [emailHtml, setEmailHtml] = useState('');
  const [emailDesign, setEmailDesign] = useState('');
  const [emailName, setEmailName] = useState('');
  const [emailLabels, setEmailLabels] = useState([]);
  const [emailAuthors, setEmailAuthors] = useState([]);
  const [saveUsModalVisible, setSaveUsModalVisible] = useState(false);
  const [settingsModalVisible, setSettingsModalVisible] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [changeNameVisible, setChangeNameVisible] = useState(false);
  const [save, saveStatus] = useSaveDesign(emailEditorRef, userData, id);
  const engine = new Liquid();

  let isLoaded = false;

  const handleDesignFetched = useCallback((design) => {
    if (isLoaded) {
      emailEditorRef.current.editor.loadDesign(design);
    }
  }, [isLoaded]);

  const [currentDesign, isDefaultDesign] = useCurrentDesign(id, handleDesignFetched, setEmailName, setEmailLabels, setEmailAuthors);

  const onLoad = () => {
    isLoaded = true;
    emailEditorRef.current.editor.loadDesign(currentDesign ? currentDesign.body : blankDesign.body);

    emailEditorRef.current.editor.registerCallback('previewHtml', (params, done) => {
      const tpl = engine.parse(params.html);
      engine.render(tpl, previewTemplates).then(html => done({html}));
    });

    // Image uploader
    // ==============
    // emailEditorRef.current.editor.registerCallback('image', (file, done) => {
    //   done({progress: 100, url: 'URL OF THE FILE'});
    // });

    // Custom blocks
    // =============
    // emailEditorRef.current.editor.registerProvider('blocks', function (params, done) {
    //   console.log('blocks provider', params);
    //   done(blocks);
    // });

  };

  const handleTabChange = (tabId) => {
    setSelectedTab(tabId);

    if (tabId !== 'tab-editor') {
      emailEditorRef.current.editor.exportHtml((data) => {
        const {design, html} = data;
        setEmailHtml(html);
        setEmailDesign(jsonFormat(design, {
          type: 'space'
        }));
      });
    }
  };

  const handleDropdownSelect = (value) => {
    switch (value) {
      case 'save_as':
        return setSaveUsModalVisible(true);

      case 'settings':
        return setSettingsModalVisible(true);

      case 'delete':
        return setDeleteModalVisible(true);

      default:
        return null;
    }
  };

  const handleSaveName = (event) => {
    const lastName = emailName + '';

    if (event.target.value && event.target.value !== lastName) {
      setEmailName(event.target.value);

      save(event.target.value).then(data => {
        if (data.type === 'error') {
          setEmailName(lastName);
        }
      });
    }

    setChangeNameVisible(false);
  };

  const handleDesignUpdate = (response) => {
    setEmailName(response.name);
    setEmailLabels(response.labels);
    setEmailAuthors(response.author_id);
  };

  return (
    <EditorTabs
      selectedItem={selectedTab}
      onChange={handleTabChange}
    >
      <SaveUsModal
        emailEditorRef={emailEditorRef}
        userData={userData}
        modalVisible={saveUsModalVisible}
        setModalVisible={setSaveUsModalVisible}
        emailName={emailName}
        emailLabels={emailLabels}
      />
      <SettingsModal
        id={id}
        modalVisible={settingsModalVisible}
        setModalVisible={setSettingsModalVisible}
        emailName={emailName}
        emailLabels={emailLabels}
        emailAuthors={emailAuthors}
        onUpdate={handleDesignUpdate}
      />
      <DeleteModal
        modalVisible={deleteModalVisible}
        setModalVisible={setDeleteModalVisible}
        emailName={emailName}
        id={id}
      />
      <Body>
        <HeaderRow>
          <HeaderCol>
            <HeaderItemStyled>
              <EditorHeaderLink href={isDefaultDesign ? '/' : '/?tab=customer'}>
                <ChevronLeftIcon/>
                {!currentDesign && <span>Theme List</span>}
              </EditorHeaderLink>
            </HeaderItemStyled>
            {currentDesign && (
              <HeaderItemStyled>
                {isDefaultDesign ? (
                  <LG>{currentDesign.name}</LG>
                ) : (
                  <>
                    {!changeNameVisible
                      ? <LG style={{cursor: 'pointer'}}
                            onClick={() => setChangeNameVisible(true)}>{emailName}</LG>
                      : <Input defaultValue={emailName}
                               onBlur={handleSaveName}
                               autoFocus={true}/>
                    }
                  </>
                )}
              </HeaderItemStyled>
            )}
          </HeaderCol>
          <HeaderCol>
            <HeaderItemStyled>
              <EditorTabList>
                <EditorTab item="tab-editor">Editor</EditorTab>
                <EditorTab item="tab-html">HTML</EditorTab>
                <EditorTab item="tab-design">Design</EditorTab>
              </EditorTabList>
            </HeaderItemStyled>
          </HeaderCol>
          <HeaderCol>
            <HeaderItemStyled>
              <Dropdown onSelect={handleDropdownSelect}>
                <Trigger>
                  <Button isBasic>
                    Actions
                    <Button.EndIcon>
                      <ChevronDownIcon/>
                    </Button.EndIcon>
                  </Button>
                </Trigger>
                <Menu>
                  {!isDefaultDesign && <Item value="settings">Email settings</Item>}
                  <Item value="save_as">Save As...</Item>
                  <Item value="export" disabled>Export Theme</Item>
                  {!isDefaultDesign && (
                    <>
                      <Separator/>
                      <Item value="delete">Delete</Item>
                    </>
                  )}
                </Menu>
              </Dropdown>
            </HeaderItemStyled>
            <HeaderItemStyled>
              {isDefaultDesign ? (
                <Button isPrimary onClick={() => setSaveUsModalVisible(true)}>Save As</Button>
              ) : (
                <Button isPrimary disabled={saveStatus === STATUS_IS_PENDING} onClick={save}>
                  {saveStatus === STATUS_IS_PENDING ? <Dots/> : 'Save'}
                </Button>
              )}
            </HeaderItemStyled>
            <HeaderItemStyled style={{marginLeft: '20px'}}>
              <UserLogout userData={userData}/>
            </HeaderItemStyled>
          </HeaderCol>
        </HeaderRow>
        <Content>
          <Main>
            <EditorTabPanel item="tab-editor">
              <EditorContainer>
                <EmailEditor
                  ref={emailEditorRef}
                  onLoad={onLoad}
                  options={editorOptions}
                />
              </EditorContainer>
            </EditorTabPanel>
            <EditorTabPanel item="tab-html">
              <CopyButton text={emailHtml}/>
              <SyntaxHighlighter
                language="htmlbars"
                style={vs2015}
                showLineNumbers={true}
                showInlineLineNumbers={true}
              >
                {emailHtml}
              </SyntaxHighlighter>
            </EditorTabPanel>
            <EditorTabPanel item="tab-design">
              <CopyButton text={emailDesign}/>
              <SyntaxHighlighter
                language="json"
                style={vs2015}
                showLineNumbers={true}
                showInlineLineNumbers={true}
              >
                {emailDesign}
              </SyntaxHighlighter>
            </EditorTabPanel>
          </Main>
        </Content>
      </Body>
    </EditorTabs>
  );
};

export default Editor;
