import { useCallback, useState } from 'react';
import { API_URL, STATUS_IS_ERROR, STATUS_IS_PENDING, STATUS_IS_SUCCESS } from '../constants';
import { useToast } from '@zendeskgarden/react-notifications';
import Alert from '../components/Alert';

export default function useDeleteDesign(id) {
  const {addToast} = useToast();
  const [status, setStatus] = useState(null);

  const deleteDesign = useCallback(async () => {
    setStatus(STATUS_IS_PENDING);

    const requestOptions = {
      method: 'DELETE',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({id})
    };

    return fetch(API_URL + '/designs', requestOptions)
      .then(response => response.json())
      .then(data => {
        if (data.type === 'error') {
          setStatus(STATUS_IS_ERROR);
          addToast(
            ({close}) => <Alert close={close} type="error" title="Delete error" text={data.message}/>,
            {placement: 'top-end'}
          );
        }
        else {
          setStatus(STATUS_IS_SUCCESS);
          window.location.replace('/');
        }
        return data;
      })
      .catch(error => {
        setStatus(STATUS_IS_ERROR);
        console.log(error);
        addToast(
          ({close}) => <Alert close={close} type="error" title="Delete error" text="Server error"/>,
          {placement: 'top-end'}
        );
        return {type: 'error'};
      });
  }, [setStatus, id, addToast]);

  return [deleteDesign, status];
};
