import styled from 'styled-components';
import { PALETTE } from '@zendeskgarden/react-theming';

export const UserPageContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const UserPageBox = styled.div`
  width: 100%;
  max-width: 420px;
  padding: 30px;
  text-align: center;
  border: 1px solid ${PALETTE.grey[300]};
`;

export const UserPageUser = styled.div`
  margin-bottom: 24px;
`;

export const UserPageError = styled.div`
  margin-bottom: 24px;
  text-align: left;
`;
