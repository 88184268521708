export const GOOGLE_CLIENT_ID = '314450367907-icgmcavajkj32qkso2elmdmkv8ltiqvv.apps.googleusercontent.com';
export const USER_HOSTED_DOMAIN = 'premiumplus.io';
export const API_URL = process.env.NODE_ENV === 'development' ? 'http://localhost:9999/.netlify/functions' : 'https://email-constructor.netlify.app/api';

export const STATUS_IS_PENDING = 'STATUS_IS_PENDING';
export const STATUS_IS_ERROR = 'STATUS_IS_ERROR';
export const STATUS_IS_SUCCESS = 'STATUS_IS_SUCCESS';

export const ALGOLIA_APPLICATION_ID = 'BOVUHCFVAF';
export const ALGOLIA_API_KEY = '7a9cee64b62061c49c9cb1ce6315f70b';
