import React from 'react';
import { Close, Notification, Title } from '@zendeskgarden/react-notifications';

const Alert = ({close, type, title, text}) => {
  return (
    <Notification type={type} style={{maxWidth: 450, zIndex: 500}}>
      <Title>{title}</Title>
      {text}
      <Close aria-label="Close" onClick={close}/>
    </Notification>
  );
};

export default Alert;
