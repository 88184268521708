import React, { useState } from 'react';
import { Button } from '@zendeskgarden/react-buttons';
import styled from 'styled-components';

const ButtonStyled = styled(Button)`
  position: fixed;
  top: 64px;
  right: 10px;
  z-index: 9;
`;

const CopyButton = ({text}) => {
  const [copyText, setCopyText] = useState('Copy');

  const handleButtonClick = async () => {
    try {
      await navigator.clipboard.writeText(text);
      setCopyText('Copied');

      setTimeout(() => {
        setCopyText('Copy');
      }, 1000);
    }
    catch (err) {
      setCopyText('Error');

      setTimeout(() => {
        setCopyText('Copy');
      }, 1000);
    }
  };

  return (
    <ButtonStyled isPrimary isDanger size="small" onClick={handleButtonClick} disabled={copyText !== 'Copy'}>
      {copyText}
    </ButtonStyled>
  );
};

export default CopyButton;
