import React, { useEffect, useState } from 'react';
import { Close, Footer, FooterItem, Header, Modal } from '@zendeskgarden/react-modals';
import { ModalBodyStyling, ModalLoading } from '../components/Editor';
import { Dots } from '@zendeskgarden/react-loaders';
import { Field, Input, Label } from '@zendeskgarden/react-forms';
import { Button } from '@zendeskgarden/react-buttons';
import useSaveUsDesign from '../hooks/useSaveUsDesign';
import { STATUS_IS_ERROR, STATUS_IS_PENDING } from '../constants';
import Labels from '../components/Labels';

const SaveUsModal = (props) => {
  const {emailEditorRef, userData, modalVisible, setModalVisible, emailName, emailLabels} = props;
  const [name, setName] = useState('');
  const [labels, setLabels] = useState([]);
  const [saveUs, status] = useSaveUsDesign(emailEditorRef, userData, emailName);

  useEffect(() => {
    setName(emailName);
    setLabels(emailLabels);
  }, [setName, emailName, emailLabels, setLabels]);

  useEffect(() => {
    if (status === STATUS_IS_ERROR) {
      setModalVisible(false);
    }
  }, [status, setModalVisible]);

  if (!modalVisible) {
    return '';
  }

  const handleSaveClick = () => {
    saveUs(name, labels);
  };

  return (
    <Modal onClose={() => setModalVisible(false)}>
      <Header>Save email template us...</Header>
      <ModalBodyStyling>
        {status === STATUS_IS_PENDING && (
          <ModalLoading>
            <Dots size={32}/>
          </ModalLoading>
        )}
        <Field>
          <Label>Name:</Label>
          <Input defaultValue={name} onInput={e => setName(e.target.value)}/>
        </Field>
        <Labels
          labels={labels}
          setLabels={setLabels}
          style={{marginTop: '24px'}}
        />
      </ModalBodyStyling>
      <Footer>
        <FooterItem>
          <Button onClick={() => setModalVisible(false)} isBasic>
            Cancel
          </Button>
        </FooterItem>
        <FooterItem>
          <Button isPrimary onClick={handleSaveClick} disabled={!emailName}>
            Save
          </Button>
        </FooterItem>
      </Footer>
      <Close aria-label="Close modal"/>
    </Modal>
  );
};

export default SaveUsModal;
