import designs from '../editor/designs';
import { API_URL } from '../constants';
import { useEffect, useState } from 'react';
import { useToast } from '@zendeskgarden/react-notifications';
import Alert from '../components/Alert';

export default function useCurrentDesign(id, handleDesignFetched, setEmailName, setEmailLabels, setEmailAuthors) {
  const {addToast} = useToast();
  const [currentDesign, setCurrentDesign] = useState(null);
  const [isDefaultDesign, setIsDefaultDesign] = useState(true);

  useEffect(() => {
      if (!id) return;

      let design = designs.filter(item => item.id === id)[0];
      if (design) {
        setEmailName(design.name);
        return setCurrentDesign(design);
      }

      const requestOptions = {
        method: 'GET',
        headers: {'Content-Type': 'application/json'}
      };

      fetch(API_URL + `/designs?id=${id}`, requestOptions)
        .then(response => response.json())
        .then(data => {
          if (data.type === 'error') {
            return addToast(
              ({close}) => <Alert close={close} type="error" title="Fetch error" text={data.message}/>,
              {placement: 'top-end'}
            );
          }

          setEmailName(data.name);
          setEmailLabels(data.labels);
          setEmailAuthors(data.author_id);
          setCurrentDesign(data);
          setIsDefaultDesign(false);

          handleDesignFetched(data.body);
        })
        .catch(error => {
          console.log(error);
          return addToast(
            ({close}) => <Alert close={close} type="error" title="Fetch error" text="Server error"/>,
            {placement: 'top-end'}
          );
        });

    },
    [setCurrentDesign, setIsDefaultDesign, id, handleDesignFetched, addToast, setEmailName, setEmailLabels, setEmailAuthors]);

  return [currentDesign, isDefaultDesign];
}
