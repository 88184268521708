import algoliasearch from 'algoliasearch';
import {
  ALGOLIA_API_KEY,
  ALGOLIA_APPLICATION_ID,
  STATUS_IS_ERROR,
  STATUS_IS_PENDING,
  STATUS_IS_SUCCESS
} from '../constants';
import { useToast } from '@zendeskgarden/react-notifications';
import { useCallback, useState } from 'react';
import Alert from '../components/Alert';

const client = algoliasearch(ALGOLIA_APPLICATION_ID, ALGOLIA_API_KEY);
const index = client.initIndex('designs');

export default function useAlgoliaSearch() {
  const {addToast} = useToast();
  const [status, setStatus] = useState(null);

  const search = useCallback(async (query, field) => {
    setStatus(STATUS_IS_PENDING);

    const params = {
      hitsPerPage: 10
    };

    if (field && field !== '-') {
      params.restrictSearchableAttributes = [field];
      params.attributesToHighlight = [field];
    }

    return await index.search(query, params)
      .then((response) => {
        setStatus(STATUS_IS_SUCCESS);
        return response;
      })
      .catch((error) => {
        setStatus(STATUS_IS_ERROR);
        console.log(error);
        addToast(
          ({close}) => <Alert close={close} type="error" title="Get designs error" text="Server error"/>,
          {placement: 'top-end'}
        );
        return {type: 'error'};
      });

  }, [setStatus, addToast]);

  return [search, status];
}
