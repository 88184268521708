import { Label } from '@zendeskgarden/react-forms';
import { Dropdown, Item, Menu, Multiselect, Field as DropdownField } from '@zendeskgarden/react-dropdowns';
import { Tag } from '@zendeskgarden/react-tags';
import { SM } from '@zendeskgarden/react-typography';
import { PALETTE } from '@zendeskgarden/react-theming';
import React from 'react';

const UsersDropdown = ({allUsers, selectedUsers, setSelectedUsers}) => {
return (
  <Dropdown
    selectedItems={selectedUsers}
    downshiftProps={{defaultHighlightedIndex: 0}}
    onSelect={items => setSelectedUsers(items)}
  >
    <DropdownField style={{marginTop: '24px'}}>
      <Label>Authors:</Label>
      <Multiselect renderItem={({value, removeValue}) => {
        const user = allUsers.filter(user => user.id === value)[0];
        return (
          <Tag>
            <Tag.Avatar>
              <img alt={user.name} src={user.image_url}/>
            </Tag.Avatar>
            <span>{user.name}</span>
            <Tag.Close onClick={() => removeValue()}/>
          </Tag>
        );
      }}/>
    </DropdownField>
    <Menu>
      {allUsers.map(user => (
        <Item key={user.id} value={user.id}>
          <span style={{display: 'block'}}>{user.name}</span>
          <SM style={{display: 'block', color: PALETTE.grey['500']}}>{user.email}</SM>
        </Item>
      ))}
    </Menu>
  </Dropdown>
)
};

export default UsersDropdown;
