import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from '@zendeskgarden/react-theming';
import App from './containers/App';
import reportWebVitals from './reportWebVitals';
import GlobalStyles from './components/GlobalStyles';

ReactDOM.render(
  <>
    <GlobalStyles/>
    <ThemeProvider style={{height: 'auto'}}>
      <App/>
    </ThemeProvider>
  </>
  ,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
