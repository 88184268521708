const editorOptions = {
  id: 'editor',
  projectId: 42374,
  displayMode: 'email',
  appearance: {
    theme: 'dark'
  },
  features: {
    textEditor: {
      tables: true
    }
  },
  fonts: {
    showDefaultFonts: true,
    customFonts: [
      {
        label: "Lucida Grande",
        value: "'Lucida Grande',Verdana,Arial,sans-serif",
      },
      {
        label: "Inter",
        value: "'Inter', sans-serif",
        url: "https://fonts.googleapis.com/css2?family=Inter"
      }
    ]
  },
  tools: {
    social: {
      enabled: true
    }
  },
  mergeTags: {
    delimiter: {
      name: '{{delimiter}}',
      value: '{{delimiter}}'
    },
    content: {
      name: '{{content}}',
      value: '{{content}}'
    },
    footer: {
      name: '{{footer}}',
      value: '{{footer}}'
    },
    footer_link: {
      name: '{{footer_link}}',
      value: '{{footer_link}}'
    }
  }
};

export default editorOptions;
