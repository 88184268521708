import { useCallback, useState } from 'react';
import { API_URL, STATUS_IS_ERROR, STATUS_IS_PENDING, STATUS_IS_SUCCESS, USER_HOSTED_DOMAIN } from '../constants';
import { useToast } from '@zendeskgarden/react-notifications';
import Alert from '../components/Alert';

const USERS_SS_KEY = 'users';
const USER_ID_SS_KEY = 'userId';

export default function useUser() {
  const {addToast} = useToast();
  const [status, setStatus] = useState(null);

  // Get cache
  const getCache = useCallback(() => {
    return [sessionStorage.getItem(USERS_SS_KEY), sessionStorage.getItem(USER_ID_SS_KEY)];
  }, []);

  // Logout
  const logout = useCallback(() => {
    sessionStorage.removeItem(USERS_SS_KEY);
    sessionStorage.removeItem(USER_ID_SS_KEY);

    window.location.reload();
  }, []);

  // Login
  const login = useCallback(async (profileData) => {
    if (profileData.hostedDomain === USER_HOSTED_DOMAIN) {
      setStatus(STATUS_IS_PENDING);

      const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({
          id: profileData.id,
          name: profileData.name,
          email: profileData.email,
          image_url: profileData.imageUrl
        })
      };

      return fetch(API_URL + '/users', requestOptions)
        .then(response => response.json())
        .then(data => {
          if (data.type === 'error') {
            setStatus(STATUS_IS_ERROR);
            return addToast(
              ({close}) => <Alert close={close} type="error" title="Login error" text={data.message}/>,
              {placement: 'top-end'}
            );
          }

          if (data.users) {
            sessionStorage.setItem(USERS_SS_KEY, JSON.stringify(data.users));
            sessionStorage.setItem(USER_ID_SS_KEY, profileData.id.toString());
          }

          setStatus(STATUS_IS_SUCCESS);
          return data;
        })
        .catch(error => {
          setStatus(STATUS_IS_ERROR);
          console.log(error);
          addToast(
            ({close}) => <Alert close={close} type="error" title="Login error" text="Server error"/>,
            {placement: 'top-end'}
          );
          return {type: 'error'};
        });
    }

    return Promise.resolve();
  }, [addToast]);

  return {login, logout, getCache, status};
}
