import { useToast } from '@zendeskgarden/react-notifications';
import { useCallback, useState } from 'react';
import { API_URL, STATUS_IS_ERROR, STATUS_IS_PENDING, STATUS_IS_SUCCESS } from '../constants';
import Alert from '../components/Alert';

export default function useUpdateDesign(id) {
  const {addToast} = useToast();
  const [status, setStatus] = useState(null);

  const update = useCallback(async (name, labels, users) => {
    setStatus(STATUS_IS_PENDING);

    const requestOptions = {
      method: 'PUT',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({
        id,
        name,
        labels,
        author_id: users
      })
    };

    return fetch(API_URL + '/designs', requestOptions)
      .then(response => response.json())
      .then(data => {
        if (data.type === 'error') {
          setStatus(STATUS_IS_ERROR);
          addToast(
            ({close}) => <Alert close={close} type="error" title="Update error" text={data.message}/>,
            {placement: 'top-end'}
          );
        }
        else {
          setStatus(STATUS_IS_SUCCESS);
        }
        return data;
      })
      .catch(error => {
        setStatus(STATUS_IS_ERROR);
        console.log(error);
        addToast(
          ({close}) => <Alert close={close} type="error" title="Update error" text="Server error"/>,
          {placement: 'top-end'}
        );
        return {type: 'error'};
      });

  }, [id, addToast, setStatus]);

  return [update, status];
}
