import styled from 'styled-components';
import { Header } from '@zendeskgarden/react-chrome';
import { Tab, TabList, TabPanel, Tabs } from '@zendeskgarden/react-tabs';
import { Anchor } from '@zendeskgarden/react-buttons';

export const HeaderRow = styled(Header)`
  justify-content: space-between;
  align-items: center;
`;

export const HeaderCol = styled.div`
  display: flex;
  align-items: center;

  &:first-child {
    flex: 1 1 10px;
  }

  &:last-child:not(:first-child) {
    flex: 1 1 10px;
    justify-content: flex-end;
  }
`;

export const HeaderItemStyled = styled.div`
  display: inline-flex;
  margin: 0 4px;
`;

export const EditorTabs = styled(Tabs)`
  width: 100vw;
  height: 100vh;
  display: flex;
`;

export const EditorTabPanel = styled(TabPanel)`
  height: 100%;

  pre {
    margin: 0;
    min-height: 100%;
  }
`;

export const EditorTabList = styled(TabList)`
  margin-bottom: 0;
  height: 52px;
  border: none;
`;

export const EditorTab = styled(Tab)`
  padding: 16px 28px 13px;
`;

export const EditorHeaderLink = styled(Anchor)`
  display: flex;
  align-self: center;

  > * {
    height: 18px;
  }

  > * + * {
    margin-left: 4px;
  }
`;
