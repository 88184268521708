import styled from 'styled-components';
import { XXL } from '@zendeskgarden/react-typography';
import { PALETTE } from '@zendeskgarden/react-theming';
import { Avatar } from '@zendeskgarden/react-avatars';

export const PageContainer = styled.div`
  margin: 0 auto;
  padding: 60px 15px;
  ${props => props.size === 'small' ? `
    max-width: 960px;
  ` : `
    max-width: 1440px;
  `}

`;

export const PageSection = styled.div`
  & + & {
    margin-top: 60px;
  }
`;

export const PageTitle = styled(XXL)`
  margin-bottom: 24px;
`;

export const DesignItem = styled.div`
  height: 0;
  position: relative;
  padding-bottom: 80%;
  border-radius: 6px;
  background-color: ${PALETTE.grey[100]};
  border: 1px solid ${PALETTE.grey[300]};
  cursor: pointer;
  transition: all .3s ease;
  overflow: hidden;

  &:hover {
    border-color: ${PALETTE.blue[400]};
  }
`;

export const DesignItemTitle = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 10px 15px;
  border-top: 1px solid ${PALETTE.grey[300]};
  background-color: #fff;
  transition: all .3s ease;
  z-index: 2;

  ${DesignItem}:hover & {
    transform: translateY(100%);
  }
`;

export const DesignItemImage = styled.img`
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`;

export const AuthorAvatar = styled(Avatar)`
  border: 1px solid #fff;

  & + & {
    margin-left: -4px;
  }
`;
