import React, { useEffect, useState } from 'react';
import { Close, Footer, FooterItem, Header, Modal } from '@zendeskgarden/react-modals';
import { ModalBodyStyling, ModalLoading } from '../components/Editor';
import { Field, Input, Label } from '@zendeskgarden/react-forms';
import { Button } from '@zendeskgarden/react-buttons';
import Labels from '../components/Labels';
import UsersDropdown from '../components/UsersDropdown';
import useUpdateDesign from '../hooks/useUpdateDesign';
import { STATUS_IS_PENDING } from '../constants';
import { Dots } from '@zendeskgarden/react-loaders';

const SettingsModal = ({id, modalVisible, setModalVisible, emailName, emailLabels, emailAuthors, onUpdate}) => {
  const [name, setName] = useState('');
  const [labels, setLabels] = useState([]);
  const [users, setUsers] = useState([]);
  const [update, status] = useUpdateDesign(id);
  const allUsers = JSON.parse(sessionStorage.getItem('users'));

  useEffect(() => {
    setName(emailName);
    setLabels(emailLabels);
    setUsers(emailAuthors);
  }, [setName, emailName, emailLabels, emailAuthors]);

  const handleUpdateClick = async () => {
    const response = await update(name, labels, users);
    setModalVisible(false);

    if (onUpdate && response.type !== 'error') {
      onUpdate(response);
    }
  };

  if (!modalVisible) {
    return '';
  }

  return (
    <Modal onClose={() => setModalVisible(false)}>
      <Header>Email settings</Header>
      <ModalBodyStyling>
        {status === STATUS_IS_PENDING && (
          <ModalLoading>
            <Dots size={32}/>
          </ModalLoading>
        )}
        <Field>
          <Label>Name:</Label>
          <Input defaultValue={name} onInput={e => setName(e.target.value)}/>
        </Field>
        <Labels
          labels={labels}
          setLabels={setLabels}
          style={{marginTop: '24px'}}
        />
        <UsersDropdown
          allUsers={allUsers}
          selectedUsers={users}
          setSelectedUsers={setUsers}
        />
      </ModalBodyStyling>
      <Footer>
        <FooterItem>
          <Button onClick={() => setModalVisible(false)} isBasic>
            Cancel
          </Button>
        </FooterItem>
        <FooterItem>
          <Button isPrimary disabled={!name || !users.length} onClick={handleUpdateClick}>
            Save
          </Button>
        </FooterItem>
      </Footer>
      <Close aria-label="Close modal"/>
    </Modal>
  );
};

export default SettingsModal;
