import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Chrome } from '@zendeskgarden/react-chrome';
import Editor from './Editor';
import User, { UserContext } from './User';
import ThemeList from './ThemeList';
import { DEFAULT_THEME } from '@zendeskgarden/react-theming';
import { ToastProvider } from '@zendeskgarden/react-notifications';

const topProps = {
  style: {top: DEFAULT_THEME.space.base * 3}
};

const bottomProps = {
  style: {bottom: DEFAULT_THEME.space.base * 3}
};

const placementProps = {
  'top-start': topProps,
  'top': topProps,
  'top-end': topProps,
  'bottom-start': bottomProps,
  'bottom': bottomProps,
  'bottom-end': bottomProps
};

const App = () => {

  return (
    <Router>
      <Chrome>
        <ToastProvider placementProps={placementProps} zIndex={1}>
          <User>
            <UserContext.Consumer>
              {userData => (
                <Switch>
                  <Route path="/editor/:id">
                    <Editor userData={userData}/>
                  </Route>

                  <Route path="/editor">
                    <Editor userData={userData}/>
                  </Route>

                  <Route path="/">
                    <ThemeList userData={userData}/>
                  </Route>
                </Switch>
              )}
            </UserContext.Consumer>
          </User>
        </ToastProvider>
      </Chrome>
    </Router>
  );
};

export default App;
