import React, { useCallback, useState } from 'react';
import {
  SearchButton,
  SearchContainer,
  SearchHit,
  SearchNoResults,
  SearchResults,
  SearchResultsBox,
  SearchResultsBoxHeading,
  StyledInputGroup
} from '../components/Search';
import { Input } from '@zendeskgarden/react-forms';
import { MD } from '@zendeskgarden/react-typography';
import useAlgoliaSearch from '../hooks/useAlgoliaSearch';
import { STATUS_IS_PENDING } from '../constants';
import { Dots } from '@zendeskgarden/react-loaders';
import { ModalLoading } from '../components/Editor';
import { useHistory } from 'react-router-dom';
import { Tag } from '@zendeskgarden/react-tags';
import { Dropdown, Item, Menu, Trigger } from '@zendeskgarden/react-dropdowns';
import { Button } from '@zendeskgarden/react-buttons';
import { ReactComponent as ChevronDownStroke } from '@zendeskgarden/svg-icons/src/16/chevron-down-stroke.svg';

let timeout = null;

const Search = () => {
  const history = useHistory();
  const [searchValue, setSearchValue] = useState('');
  const [searchField, setSearchField] = useState('-');
  const [searchResultsVisible, setSearchResultsVisible] = useState(false);
  const [search, status] = useAlgoliaSearch();
  const [searchHits, setSearchHits] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  const searchTimeOut = useCallback((value, field) => {
    clearTimeout(timeout);
    timeout = setTimeout(async () => {
      const response = await search(value, field);
      if (response.type === 'error') {
        setSearchHits([]);
      }
      else {
        setSearchHits(response.hits);
      }
    }, 300);
  }, [search]);

  const handleSearchInput = async (event) => {
    setSearchResultsVisible(event.target.value.length > 1);

    if (event.target.value !== searchValue) {
      setSearchValue(event.target.value);

      if (event.target.value.length > 1) {
        searchTimeOut(event.target.value, searchField);
      }
    }
  };

  const getSearchFieldName = (value = '') => {
    switch (value) {
      case 'name':
        return 'Search by name';
      case 'labels':
        return 'Search by label';
      default:
        return 'Search by';
    }
  };

  return (
    <SearchContainer>
      <StyledInputGroup>
        <Input
          placeholder="Search themes"
          onFocus={handleSearchInput}
          onInput={handleSearchInput}
          onBlur={() => setSearchResultsVisible(false)}
        />
        <Dropdown
          isOpen={isOpen}
          selectedItem={searchField}
          onSelect={item => setSearchField(item)}
          downshiftProps={{defaultHighlightedIndex: 0}}
          onStateChange={state => {
            if (state.isOpen !== undefined) {
              setIsOpen(state.isOpen);
            }
          }}
        >
          <Trigger>
            <SearchButton focusInset isNeutral>
              {getSearchFieldName(searchField)}
              <Button.EndIcon isRotated={isOpen}>
                <ChevronDownStroke/>
              </Button.EndIcon>
            </SearchButton>
          </Trigger>
          <Menu>
            <Item value="-">-</Item>
            <Item value="name">{getSearchFieldName('name')}</Item>
            <Item value="labels">{getSearchFieldName('labels')}</Item>
          </Menu>
        </Dropdown>
      </StyledInputGroup>
      {searchResultsVisible && (
        <SearchResultsBox onMouseDown={e => e.preventDefault()}>
          <SearchResultsBoxHeading>
            <MD>Search results: "<b>{searchValue}</b>"</MD>
          </SearchResultsBoxHeading>
          <SearchResults>

            {(status === STATUS_IS_PENDING) && (
              <ModalLoading>
                <Dots size={32}/>
              </ModalLoading>
            )}

            {(status !== STATUS_IS_PENDING && !searchHits.length) && (
              <SearchNoResults>
                <MD>Themes not found</MD>
              </SearchNoResults>
            )}

            {searchHits.map(hit => (
              <SearchHit key={hit.objectID} onClick={() => history.push('/editor/' + hit.objectID)}>
                {hit._highlightResult.name
                  ? <MD className="name" dangerouslySetInnerHTML={{__html: hit._highlightResult.name.value}}/>
                  : <MD className="name">{hit.name}</MD>
                }

                <div>
                  {hit._highlightResult.labels
                    ? hit._highlightResult.labels.map(label => (
                      <Tag key={label.value} style={{marginRight: '4px'}} hue={label.matchLevel === 'full' ? 'yellow' : ''}>
                        <span dangerouslySetInnerHTML={{__html: label.value}}/>
                      </Tag>
                    ))
                    : hit.labels.map(label => (
                      <Tag key={label} style={{marginRight: '4px'}}>
                        <span>{label}</span>
                      </Tag>
                    ))}
                </div>
              </SearchHit>
            ))}

          </SearchResults>
        </SearchResultsBox>
      )}
    </SearchContainer>
  );
};

export default Search;
