import React from 'react';
import { DesignItem, DesignItemImage, DesignItemTitle, PageSection, PageTitle } from './Page';
import { Col, Row } from '@zendeskgarden/react-grid';

const ThemesContainer = ({title, themes, history}) => {
  return (
    <PageSection>
      <PageTitle>{title}</PageTitle>
      <Row>
        {themes.map(item => (
          <Col size={3} key={item.id} style={{marginBottom: '20px'}}>
            <DesignItem onClick={() => history.push('/editor/' + item.id)}>
              {item.thumbnail && <DesignItemImage src={item.thumbnail}/>}
              <DesignItemTitle>{item.name}</DesignItemTitle>
            </DesignItem>
          </Col>
        ))}
      </Row>
    </PageSection>
  );
};

export default ThemesContainer;
