import React, { useState } from 'react';
import { Field, Input, Label } from '@zendeskgarden/react-forms';
import { PALETTE } from '@zendeskgarden/react-theming';
import { Tag } from '@zendeskgarden/react-tags';

const Labels = ({style, labels, setLabels}) => {
  const [isLabelsError, setIsLabelsError] = useState(false);

  const addLabel = (input) => {
    if (input.value && input.value.length > 1 && labels.indexOf(input.value) === -1) {
      const newLabels = Array.from(labels);
      newLabels.push(input.value.replace(/^[\s]*([\s\S]+)[\s]*$/, '$1'));
      setLabels(newLabels);
      input.value = '';
    }
    else {
      setIsLabelsError(true);
    }
  };

  const deleteLabel = (label) => {
    const newLabels = Array.from(labels);
    const index = newLabels.indexOf(label);

    if (index !== -1) {
      newLabels.splice(index, 1);
      setLabels(newLabels);
    }
  };

  const handleAddLabel = (event) => {
    setIsLabelsError(false);
    if (event.type === 'keypress' && (event.code === 'Enter' || event.code === 'Comma')) {
      event.preventDefault();
      return addLabel(event.target);
    }
  };

  return (
    <Field style={style}>
      <Label style={isLabelsError ? {color: PALETTE.red['600']} : {}}>
        Labels: {isLabelsError && '(Error)'}
      </Label>
      <Input
        onKeyPress={handleAddLabel}
        onChange={handleAddLabel}

      />
      <div style={{marginTop: '8px'}}>
        {labels.map(label => (
          <Tag key={label} style={{marginRight: '4px'}}>
            <span>{label}</span>
            <Tag.Close onClick={() => deleteLabel(label)}/>
          </Tag>
        ))}
      </div>
    </Field>
  );
};

export default Labels;
