import styled from 'styled-components';
import { PALETTE } from '@zendeskgarden/react-theming';
import { InputGroup } from '@zendeskgarden/react-forms';
import { Button } from '@zendeskgarden/react-buttons';

export const StyledInputGroup = styled(InputGroup)`
  & > input[aria-autocomplete='list'] {
    position: absolute;
  }
`;

export const SearchContainer = styled.div`
  margin-bottom: 25px;
  position: relative;
  z-index: 9;
`;

export const SearchResultsBox = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 6px 12px rgba(0, 0, 0, .1);
  margin-top: 4px;
  overflow: hidden;
`;

export const SearchResultsBoxHeading = styled.div`
  padding: 20px 25px;
  border-bottom: 1px solid ${PALETTE.grey['200']};
`;

export const SearchResults = styled.div`
  min-height: 60px;
  max-height: 400px;
  position: relative;
  overflow-y: auto;
  padding: 10px 0;
`;

export const SearchNoResults = styled.div`
  padding: 10px 25px;
`;

export const SearchHit = styled.div`
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  padding: 10px 25px;
  transition: all .3s ease;

  &:hover {
    background-color: ${PALETTE.grey['100']};
  }

  & + & {
    border-top: 1px solid ${PALETTE.grey['200']};
  }
  
  em {
    font-style: normal;
  }
  
  .name em {
    padding: 0;
    border-radius: 2px;
    background-color: ${PALETTE.yellow['200']};
    font-weight: bold;
  }
`;

export const SearchButton = styled(Button)`
  width: 180px;
  justify-content: space-between;
  border-radius: 0 4px 4px 0 !important;
`
