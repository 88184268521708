import blank from './1-blank.json';
import defaultZendesk from './2-default-zendesk.json';
import logoLeft from './3-logo-left.json';
import logoCenter from './4-logo-center.json';
import logoRight from './5-logo-right.json';
import menuBottom from './6-menu-bottom.json';
import banner from './7-banner.json';
import stockholm from './101-stockholm.json';
import gotham from './102-gotham.json';
import paris from './103-paris.json';
import capetown from './104-capetown.json';
import sydney from './105-sydney.json';
import eleven from './106-eleven.json';
import punch from './107-punch.json';
import crawl from './108-crawl.json';
import dribble from './109-dribble.json';

const designs = [
  blank,
  defaultZendesk,
  logoLeft,
  logoCenter,
  logoRight,
  menuBottom,
  banner,
  stockholm,
  gotham,
  paris,
  capetown,
  sydney,
  eleven,
  punch,
  crawl,
  dribble
];

export default designs;
