import { API_URL, STATUS_IS_ERROR, STATUS_IS_PENDING, STATUS_IS_SUCCESS } from '../constants';
import { useToast } from '@zendeskgarden/react-notifications';
import { useCallback, useState } from 'react';
import Alert from '../components/Alert';

export default function useAllDesigns() {
  const {addToast} = useToast();
  const [status, setStatus] = useState(null);

  const getDesigns = useCallback((page = 1, perPage = 25) => {
    setStatus(STATUS_IS_PENDING);

    const requestOptions = {
      method: 'GET',
      headers: {'Content-Type': 'application/json'}
    };

    return fetch(API_URL + `/designs?page=${page}&per_page=${perPage}`, requestOptions)
      .then(response => response.json())
      .then(data => {
        if (data.type === 'error') {
          setStatus(STATUS_IS_ERROR);
          addToast(
            ({close}) => <Alert close={close} type="error" title="Get designs error" text={data.message}/>,
            {placement: 'top-end'}
          );
        }
        else {
          setStatus(STATUS_IS_SUCCESS);
        }
        return data;

      })
      .catch(error => {
        setStatus(STATUS_IS_ERROR);
        console.log(error);
        addToast(
          ({close}) => <Alert close={close} type="error" title="Get designs error" text="Server error"/>,
          {placement: 'top-end'}
        );
        return {type: 'error'};
      });
  }, [setStatus, addToast]);

  return [getDesigns, status];
}
