import { useCallback, useState } from 'react';
import { API_URL, STATUS_IS_ERROR, STATUS_IS_PENDING, STATUS_IS_SUCCESS } from '../constants';
import { useToast } from '@zendeskgarden/react-notifications';
import Alert from '../components/Alert';

export default function useSaveUsDesign(emailEditorRef, userData) {
  const {addToast} = useToast();
  const [status, setStatus] = useState(null);

  const saveUs = useCallback(async (name, labels) => {
    setStatus(STATUS_IS_PENDING);

    const design = await new Promise((resolve) => {
      emailEditorRef.current.editor.exportHtml((data) => {
        resolve(data.design);
      });
    });

    const requestOptions = {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({
        author_id: userData.id,
        name: name,
        body: design,
        labels: labels
      })
    };

    return fetch(API_URL + '/designs', requestOptions)
      .then(response => response.json())
      .then(data => {
        if (data.type === 'error') {
          setStatus(STATUS_IS_ERROR);
          return addToast(
            ({close}) => <Alert close={close} type="error" title="Save As error" text={data.message}/>,
            {placement: 'top-end'}
          );
        }

        setStatus(STATUS_IS_SUCCESS);
        window.location.replace('/editor/' + data.id);
      })
      .catch(error => {
        setStatus(STATUS_IS_ERROR);
        console.log(error);
        addToast(
          ({close}) => <Alert close={close} type="error" title="Save As error" text="Server error"/>,
          {placement: 'top-end'}
        );
        return {type: 'error'};
      });
  }, [emailEditorRef, userData, setStatus, addToast]);

  return [saveUs, status];
}
