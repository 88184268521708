import styled from 'styled-components';
import { Body } from '@zendeskgarden/react-modals';

export const EditorContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const ModalBodyStyling = styled(Body)`
  position: relative;
`;

export const ModalLoading = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, .7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
`;
