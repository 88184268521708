import React, { useEffect, useState } from 'react';
import { Anchor, Button } from '@zendeskgarden/react-buttons';
import { useHistory, useLocation } from 'react-router-dom';
import { Body, Content, Main } from '@zendeskgarden/react-chrome';
import {
  EditorTab,
  EditorTabList,
  EditorTabPanel,
  EditorTabs,
  HeaderCol,
  HeaderItemStyled,
  HeaderRow
} from '../components/EditorHeader';
import { UserLogout } from './User';
import { AuthorAvatar, PageContainer, PageSection } from '../components/Page';
import designs from '../editor/designs';
import { Dots } from '@zendeskgarden/react-loaders';
import { Alert, Title } from '@zendeskgarden/react-notifications';
import {
  Body as TableBody,
  Cell,
  Head,
  HeaderCell,
  HeaderRow as TableHeaderRow,
  Row as TableRow,
  Table
} from '@zendeskgarden/react-tables';
import { PALETTE } from '@zendeskgarden/react-theming';
import { Pagination } from '@zendeskgarden/react-pagination';
import ThemesContainer from '../components/ThemesContainer';
import useAllDesigns from '../hooks/useAllDesigns';
import { STATUS_IS_PENDING } from '../constants';
import { ModalLoading } from '../components/Editor';
import Search from './Search';
import { ReactComponent as PlusIcon } from '@zendeskgarden/svg-icons/src/12/plus-fill.svg';
import { LG } from '@zendeskgarden/react-typography';
import { Tag } from '@zendeskgarden/react-tags';

const ThemeList = ({userData}) => {
  const query = new URLSearchParams(useLocation().search);
  const history = useHistory();
  const [selectedTab, setSelectedTab] = useState('default');
  const [allThemes, setAllThemes] = useState({designs: [], page: 1});
  const [getDesigns, status] = useAllDesigns();
  const users = JSON.parse(sessionStorage.getItem('users'));

  useEffect(() => {
    const tab = query.get('tab');
    if (tab && /default|customer/.test(tab)) {
      setSelectedTab(query.get('tab'));
    }

  }, [setSelectedTab, query]);

  useEffect(() => {
    if (selectedTab === 'customer' && !allThemes.designs.length) {
      getDesigns().then(setAllThemes);
    }
  }, [selectedTab, allThemes, getDesigns]);

  const handleTabChange = (value) => {
    setSelectedTab(value);
    query.set('tab', value);
    history.push('/?' + query.toString());
  };

  const handlePaginationChange = (page) => {
    getDesigns(page).then(setAllThemes);
  };

  const getAuthors = (authorIds) => {
    let authors = [];

    authorIds.forEach(authorId => {
      authors.push(users.filter(user => user.id === authorId.toString())[0]);
    });

    return authors;
  };

  return (
    <EditorTabs
      selectedItem={selectedTab}
      onChange={handleTabChange}
    >
      <Body>
        <HeaderRow>
          <HeaderCol>
            <HeaderItemStyled>
              <img src="/logo.png"
                   alt=""
                   width="auto"
                   height="32px"
              />
            </HeaderItemStyled>
            <HeaderItemStyled>
              <LG>Email constructor</LG>
            </HeaderItemStyled>
          </HeaderCol>
          <HeaderCol>
            <EditorTabList>
              <EditorTab item="default">Default themes</EditorTab>
              <EditorTab item="customer">Customer themes</EditorTab>
            </EditorTabList>
          </HeaderCol>
          <HeaderCol>
            <HeaderItemStyled>
              <Button isBasic onClick={() => history.push('/editor')}>
                <PlusIcon/>
                <span>&nbsp;Create new theme</span>
              </Button>
            </HeaderItemStyled>
            <HeaderItemStyled>
              <UserLogout userData={userData}/>
            </HeaderItemStyled>
          </HeaderCol>
        </HeaderRow>
        <Content>
          <Main>
            <EditorTabPanel item="default">
              <PageContainer>
                <ThemesContainer
                  title="Simple themes"
                  themes={designs.filter(item => item.scope === 'simple')}
                  history={history}
                />
                <ThemesContainer
                  title="Specific themes"
                  themes={designs.filter(item => item.scope === 'specific')}
                  history={history}
                />
              </PageContainer>
            </EditorTabPanel>
            <EditorTabPanel item="customer">
              <PageContainer size="small">
                <PageSection>

                  <Search/>

                  {(status === STATUS_IS_PENDING && !allThemes.designs.length) && (
                    <div style={{textAlign: 'center'}}>
                      <Dots size={32}/>
                    </div>
                  )}

                  {(status !== STATUS_IS_PENDING && !allThemes.designs.length && allThemes.page === 1) && (
                    <Alert type="info">
                      <Title>No themes</Title>
                      Create your first theme in the
                      <Anchor onClick={() => history.push('/editor')}>constructor ></Anchor>
                    </Alert>
                  )}

                  {!!(allThemes.designs.length) && (
                    <>
                      <div style={{position: 'relative'}}>
                        {status === STATUS_IS_PENDING && (
                          <ModalLoading>
                            <Dots size={32}/>
                          </ModalLoading>
                        )}
                        <Table>
                          <Head>
                            <TableHeaderRow>
                              <HeaderCell width="40%">Name</HeaderCell>
                              <HeaderCell width="30%">Labels</HeaderCell>
                              <HeaderCell width="20%">Edited at</HeaderCell>
                              <HeaderCell width="10%">Author</HeaderCell>
                            </TableHeaderRow>
                          </Head>
                          <TableBody>
                            {allThemes.designs.map(theme => (
                              <TableRow key={theme.id} onClick={() => history.push('/editor/' + theme.id)}>
                                <Cell isTruncated>{theme.name}</Cell>
                                <Cell>{theme.labels.map(label => (
                                  <Tag key={label} style={{marginRight: '4px'}}>
                                    <span>{label}</span>
                                  </Tag>
                                ))}</Cell>
                                <Cell>{new Date(theme.edited_at).toLocaleString()}</Cell>
                                <Cell>
                                  {getAuthors(theme.author_id).map(author => (
                                    <AuthorAvatar key={author.id} backgroundColor={PALETTE.grey[600]} size="extrasmall">
                                      <img src={author.image_url} alt={author.name}/>
                                    </AuthorAvatar>
                                  ))}
                                </Cell>
                              </TableRow>
                            ))}

                          </TableBody>
                        </Table>
                      </div>
                      {(allThemes.designs.length < allThemes.count) && (
                        <Pagination
                          totalPages={Math.round(allThemes.count / allThemes.per_page)}
                          pagePadding={2}
                          currentPage={allThemes.page}
                          onChange={handlePaginationChange}
                          style={{marginTop: '16px'}}
                        />
                      )}
                    </>
                  )}

                </PageSection>
              </PageContainer>
            </EditorTabPanel>
          </Main>
        </Content>
      </Body>
    </EditorTabs>
  );
};

export default ThemeList;
